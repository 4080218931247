<template>
	<div class="f82m4">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>E92-M3 ASSIST M3</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									E92-M3 ASSIST M3
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div id="main-info" class="section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6">
						<h2 class="px-2">E92-M3 ASSIST M3</h2>
						<h3>Concept</h3>
						<p class="p-2">
							ASSISTチューニングの基盤を創り上げたM3です。<br />
							E46-M3のサスペンション構造から一新されたE92-M3サスペンションを4年の歳月を掛けて2011年に筑波サーキットで1’00”343の成績を残しました。<br />
							ソフトな乗り心地でありながらショックアブソーバーが底突きすることなくハードなスプリングをしなやかにコントロールする技術は、高性能ショックアブソーバーの
							セッティングでサスペンションが繊細に動くようにマウントやアームのチューニングと独自のジオメトリーによってサーキットを充分に楽しめてストリートでもまたストレスの無い
							乗り心地で高次元に実現しています。<br />
							その他、LAPTORRエキゾーストやOS技研製LSDの開発には1年ほど要して最善の製品にすることができました。<br />
							一つ一つの開発に長い年月を掛けて妥協の無いチューニングは次世代のM4へも引き継がれています。<br />
							Hot-Versionに呼ばれるようになったのはASSIST-92M3のフットワークの良さが切っ掛けでした。
						</p>
					</div>
					<div class="col-12 col-md-6 mb-4">
						<img
							src="/img/concept-cars/e92-m3/images/4.jpg"
							class="img-fluid"
							alt="画像08"
						/>
						<div class="my-4">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<th scope="row">車両</th>
										<td>BMW E92 M3</td>
									</tr>
									<tr>
										<th scope="row">仕様</th>
										<td>6速マニュアル 左ハンドル</td>
									</tr>
									<tr>
										<th scope="row">ボディカラー</th>
										<td>300 アルピンホワイト</td>
									</tr>
									<tr>
										<th scope="row">モデル</th>
										<td>2007年 ABA-WD40</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--<div id="achievement" class="block section-padding-50-0">
			<div class="container">
				<h3>Achievement</h3>
				<div class="row mb-5">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/01.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Hot Version 峠最強伝説 魔王 2019</h6>
							<h4>魔王 ASSIST M4</h4>
						</div>
						<p class="m-0 p-2">
							2018 年 11 月の魔王決定戦で WINNER となり、2019
							年度の魔王になりました。 1 対 1
							で峠をバトルして引き離せれば勝ち、ベタ付けされれば負け、前後入替えで
							2 回戦って勝敗を決定
							します。トーナメントで勝ち続けた者が魔王になります。
							軽量でハイパワー車が有利とされていた魔王決定戦で 1,500kg 超えの M4
							が魔王になれたのはコーナリン グフォース無しでは語れない栄光です。
						</p>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/02.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Time attack</h6>
							<h4>タイムアタック</h4>
						</div>
						<p class="m-0 p-2">
							2020 年の Attack
							筑波はフロントスポイラー損傷により正しく計測できませんでした。
						</p>
						<ul class="mb-2 ml-2">
							<li class="font-weight-bold">
								2019 年 2 月 Attack 筑波 0' 58" 818 ドライバー 谷口信輝
							</li>
							<li class="font-weight-bold">
								2018 年 2 月 Attack 鈴鹿 2' 15" 598 ドライバー 谷口信輝
							</li>
						</ul>
						<p class="m-0 p-2">
							2019 年まではノーマルタービンで 2020
							年からハイフローターボでパワーアップしていますので
							今後のタイムアタックでタイムアップは期待できそうです。
						</p>
					</div>
				</div>
			</div>
		</div>-->

		<div class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div id="photo_gallery" class="col-12 col-sm-6">
						<h3>Photo Gallery</h3>

						<div class="row images">
							<div v-for="item in 8" :key="'photo' + item" class="col-12">
								<img
									:src="'/img/concept-cars/e92-m3/images/' + item + '.jpg'"
									:class="{
										'img-fluid': true,
										'img-thumbnail': true,
										'mb-3': true,
										'd-none': item == 1 ? false : true,
									}"
									:alt="'画像' + item"
									data-size="600x400"
								/>
								<p
									:class="{
										caution: true,
										'd-none': item == 1 ? false : true,
									}"
								>
									※ 写真をクリックするとギャラリーが見れます
								</p>
							</div>
						</div>

						<!-- Root element of PhotoSwipe. Must have class pswp. -->
						<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
							<div class="pswp__bg"></div>
							<div class="pswp__scroll-wrap">
								<div class="pswp__container">
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
								</div>
								<div class="pswp__ui pswp__ui--hidden">
									<div class="pswp__top-bar">
										<div class="pswp__counter"></div>
										<button
											class="pswp__button pswp__button--close"
											title="Close (Esc)"
										></button>
										<button
											class="pswp__button pswp__button--share"
											title="Share"
										></button>
										<button
											class="pswp__button pswp__button--fs"
											title="Toggle fullscreen"
										></button>
										<button
											class="pswp__button pswp__button--zoom"
											title="Zoom in/out"
										></button>
										<div class="pswp__preloader">
											<div class="pswp__preloader__icn">
												<div class="pswp__preloader__cut">
													<div class="pswp__preloader__donut"></div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="
											pswp__share-modal
											pswp__share-modal--hidden
											pswp__single-tap
										"
									>
										<div class="pswp__share-tooltip"></div>
									</div>
									<button
										class="pswp__button pswp__button--arrow--left"
										title="Previous (arrow left)"
									></button>
									<button
										class="pswp__button pswp__button--arrow--right"
										title="Next (arrow right)"
									></button>
									<div class="pswp__caption">
										<div class="pswp__caption__center"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="movies" class="col-12 col-sm-6">
						<div id="movies" class="headline">
							<h3>Movies</h3>
						</div>
						<div class="row item">
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/dttIng4V37c"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2011.12.09</small><br /><a
										href="https://youtu.be/dttIng4V37c"
										target="_blank"
										>REV-SPEED 筑波スーパーバトル BEST-LAP
									</a>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/0PSw5yfeBKQ"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2011.12.09</small><br /><a
										href="https://youtu.be/0PSw5yfeBKQ"
										target="_blank"
										>REV-SPEED 筑波スーパーバトル ウェットバトル
									</a>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/_AuKYMBaB7Y"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2011.11.28</small><br /><a
										href="https://youtu.be/_AuKYMBaB7Y"
										target="_blank"
										>REV-SPEED サーキットランミーティング鈴鹿
									</a>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="spec" class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div id="movies" class="headline">
							<h3>Spec</h3>
						</div>

						<h4 class="mb-3">ENGINE COMPONENT</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/1211.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">DMEチューニング</span>
								<h5>ASSIST DME TUNING</h5>
								<h6>アシスト DMEチューニング</h6>
								<p>
									オリジナルデータでコントロール性に優れたセッティングに仕上げています。
								</p>
							</div>
						</div>

						<h4 class="mb-3">INTAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/1321.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアクリーナーボックス</span>
								<h5>GruppeM RAM AIR SYSTEM</h5>
								<h6>グループエム ラムエアシステム</h6>
								<p>パワーアップで空気量アップをスムーズに取り込みます。</p>
							</div>
						</div>

						<h4 class="mb-3">EXHAUST</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/1811.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エキゾースト</span>
								<h5>LAPTORR EXHAUST SYSTEM</h5>
								<h6>ラプタ エキゾーストシステム</h6>
								<p>触媒レス、パワー重視仕様。</p>
							</div>
						</div>

						<h4 class="mb-3">DRIVE TRAIN</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/common/no_image.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">クラッチ</span>
								<h5>OS-GIKEN STREET MASTER TWIN CARBON CLUTCH KIT</h5>
								<h6>
									オーエス技研 ストリートマスター ツインカーボンクラッチキット
								</h6>
								<p>
									変速ロスを極限まで抑えたツインカーボンディスクと軽量化によるピックアップの向上。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/2911.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ディファレンシャル</span>
								<h5>OS-GIKEN LSD (TCD)</h5>
								<h6>オーエス技研 LSD</h6>
								<p>
									オリジナルのセッティングで電子制御をキャンセルしたディファレンシャル。
								</p>
							</div>
						</div>

						<h4 class="mb-3">FOOTWORK</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3001.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションキット</span>
								<h5>QUANTUM T3-CR ASSIST tuning</h5>
								<h6>クアンタム T3-CR ASSISTオリジナル</h6>
								<p>
									リザーバータンク、2WAY減衰調整タイプ、フロント全長調整式ダンパーストリートからタイムアタックまで使用できる超ワイドレンジの車高調サスキット。ダンパーの仕様変更は自社アップデート。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3121.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									LAPTORR MONO BALL BEARING MOUNT for Front Top with Camber
									/Caster Adjustabl
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント フロント トップ
									キャンバー／キャスター調整式
								</h6>
								<p>キャンバ、キャスター調整タイプ</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3122.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for FRONT Tension Strut</h5>
								<h6>
									ラプタ モノボールベアリングマウント フロント テンションアーム
								</h6>
								<p>
									ネガキャンバー、ワイドタイヤを装着による無駄な動きを抑えます。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3131.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>LAPTORR FRONT LOWER ARM Adjustable</h5>
								<h6>ラプタ フロント ロアアーム 調整式</h6>
								<p>
									フロントサスの強化。アッパーマウントとセットでネガキャンバー・ハイキャスターにします。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3321.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Camber Arm IN-side</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア キャンバーアーム IN側
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3322.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Trailing Arm</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア トレーリングアーム
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3323.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Rear Upper Arm</h5>
								<h6>ラプタ モノボールベアリングマウント リア アッパーアーム</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3331.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>LAPTORR REAR LOWER ARM Adjustable</h5>
								<h6>ラプタ リア ロアアーム 調整式</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
						</div>

						<h4 class="mb-3">TIRE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3701.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">タイヤ</span>
								<h5>YOKOHAMA ADVAN A052</h5>
								<h6>ヨコハマ アドバン A052</h6>
								<p>(F/R) 295/30-18<br />ADVAN最強のラジアルタイヤ。</p>
							</div>
						</div>

						<h4 class="mb-3">WHEELS</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3601.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ホイール</span>
								<h5>RAYS VOLKRACING TE37 18"-11.0J</h5>
								<h6>レイズ ヴォルクレーシング TE37 18"-11.0J</h6>
								<p>
									(F/R) 18x11.0J +37<br />295/30-18サイズのタイヤを装着するためのM4専用ASSISTオリジナル。リアは加工無しで装着可能。フロントはフェンダー加工して装着。
								</p>
							</div>
						</div>

						<h4 class="mb-3">BRAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3401.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキシステム</span>
								<h5>brembo racing BRAKE SYSTEM</h5>
								<h6>ブレンボレーシング ブレーキシステム</h6>
								<p>
									(F) 鍛造モノブロック 6ピストンキャリパー(R) 鍛造モノブロック
									4ピストンキャリパー<br />
									ローター (F) 380mmx34mm (R) 370mmx32mm
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/3431.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキパッド</span>
								<h5>ENDLESS CIRCUIT COMPOUND</h5>
								<h6>エンドレス サーキットコンパウンド</h6>
								<p>
									摩擦係数ラインナップでドライバーに合わせたセッティングが可能。
								</p>
							</div>
						</div>

						<h4 class="mb-3">EXTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5101.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ワイドボディ</span>
								<h5>FRONT FENDER WIDE</h5>
								<h6>Fフェンダーワイド化</h6>
								<p>ADVAN A052 295/30-18を装着するためにワイド化</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5121.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">フロントスポイラー</span>
								<h5>LAPTORR FRONT SPOILER</h5>
								<h6>ラプタ フロントスポイラー</h6>
								<p>
									ダウンフォース、整流、クーリング、効果のあるエアロダイナミクス
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5122.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">アンダースポイラー</span>
								<h5>LAPTORR UNDER SPOILER</h5>
								<h6>ラプタ アンダースポイラー</h6>
								<p>
									フロントスポイラーとの装着でダウンフォースを発生しています。オイルクーラー排出効果の高い開口部になっています。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5141.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サイドスカート</span>
								<h5>LAPTORR SIDE SKIRT</h5>
								<h6>ラプタ サイドスカート</h6>
								<p>ボディ側面から入り込む</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5171.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>VOLTEX GT-WING</h5>
								<h6>ボルテックス GTウィング</h6>
								<p>リアダウンフォースの必需品</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5172.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>LAPTORR GT WING STAY T001</h5>
								<h6>ラプタGTウィングステー T001</h6>
								<p>
									GTウィングを最適な位置へ再セットアップしたステー。アルミニウム製
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/6301.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">レトロフィット</span>
								<h5>BMW LCI TAIL LENS</h5>
								<h6>BMW LCI テールレンズ</h6>
								<p>後期モデルのテールレンズ。</p>
							</div>
						</div>

						<h4 class="mb-3">INTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5211.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>RECARO PRO RACER SPA HANS (CARBON)</h5>
								<h6>レカロ プロレーサー SPA ハンズ（カーボン）</h6>
								<p>
									抜群の高剛性。Sタイヤ装着で高速コーナリングでもドライビングフォームが崩れること無くホールドしてくれます。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/5212.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>LAPTORR SEAT RAIL ZERO OFFSET</h5>
								<h6>ラプタ シートレール ゼロオフセット</h6>
								<p>
									シートとステアリングとのオフセットをゼロ。メーカーに比べレールの強度アップされている。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/e92-m3/tuning/6101.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ロガー</span>
								<h5>AIM LOGGER SYSTEM</h5>
								<h6>AIMロガーシステム</h6>
								<p>
									CANbusからロガー記録。車両の状態を詳細にみることができる。GPS搭載でラップタイムやドライビングデータもとれるため、ドライビングのスキルアップには大きな武器である。
								</p>
							</div>
						</div>
					</div>

					<!-- Blog Sidebar Area -->
					<!--<div class="col-12 col-lg-4">
						<Sidebar></Sidebar>
					</div>-->
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipe from "photoswipe/dist/photoswipe.min.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js";

export default {
	title: "E92-M3 ASSIST M3",
	components: {
		//Sidebar,
	},
	mounted: function () {
		const setup = () => {
			// 画像リストを作成
			const items = Array.from(document.querySelectorAll("img[data-size]")).map(
				(i) => {
					const wh = i.getAttribute("data-size").split("x");
					return {
						src: i.getAttribute("src"),
						w: parseInt(wh[0], 10),
						h: parseInt(wh[1], 10),
					};
				}
			);
			// photoswipe.htmlで定義したルート
			const pswpElement = document.querySelector(".pswp");
			// 画像クリック時にギャラリーが表示されるようにする
			document.querySelectorAll("img[data-size]").forEach((e, i) => {
				e.onclick = () => {
					const gallery = new PhotoSwipe(
						pswpElement,
						PhotoSwipeUI_Default,
						items,
						{
							shareEl: false,
							index: i,
						}
					);
					gallery.init();
				};
			});
		};
		setup();
	},
};
</script>

<style scoped lang="scss">
#main-info {
	background-color: #152535;
	h2 {
		font-size: 3rem;
		color: #fff;
	}
	h3 {
		padding: 0.5rem;
		border-bottom: 3px dotted #fff;
		color: #fff;
	}
	p {
		margin: 0 0 1rem 0;
		color: #fff;
	}
	table {
		th,
		td {
			color: #fff;
		}
	}
}
#achievement {
	.title {
		margin-bottom: 1rem;
		padding: 1rem 1rem 0.5rem 1rem;
		border-bottom: 2px solid #152535;
	}
}
#photo_grallery {
	p.caution {
		color: #152535;
		font-size: 0.8rem;
	}
}
#movies {
	small {
		font-size: 0.8rem;
	}
}
#spec {
	h4 {
		font-size: 1rem;
		padding: 1rem;
		border-bottom: 3px dotted #152535;
		background: #f4f4f4;
	}
	h5 {
		margin-bottom: 1.5rem;
		color: #152535;
		font-size: 1rem;
		font-weight: 500;
		a {
			color: #152535;
			font-size: 1rem;
			text-decoration: underline;
		}
	}
	h6 {
		padding: 0.6rem;
		color: #152535;
		font-size: 0.8rem;
		background: #f4f4f4;
	}
	p {
		color: #152535;
		font-size: 0.8rem;
	}
}
</style>
